const StateData = {
  disciplineAni: [],
  capitole: [],
  topics: [],
  evaluations: [],
  evaluations1: [],
  evaluations2: [],
  evaluations3: [],
  breadcrumb: [],
  teachersForSubtitle: [],
  currentLevel: 1,
  currentSubject: null,
  currentTheme: null,
  currentTopic: null,
  currentTests: [],
  currentSummativeTests: [],
  currentIndexTest: 0,
  currentStudent: 1,
  currentYear: 2022,
  themeVideo: null,
}
export default StateData